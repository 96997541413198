import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { DrxIDPAll } from './index';
import { defaultDevSettings, DrxIdentityService } from './index';
//import {DrxIdentityServiceConfig, defaultDevSettings, DrxIdentityAuthContext, LoggedInUser, DrxIdentityProviderStatus} from './index'
// Define the shape of the context state

interface State {
  user: any;
  isAuthenticated: boolean;
  login: (user: any) => void;
  logout: () => void;
}


var settings = Object.assign({}, defaultDevSettings)
settings = Object.assign(settings, { apiBaseUrl: 'http://localhost:4005' })
var drxIdentityService;

// Create the context with a default value
const IDPContext = createContext<DrxIDPAll.DrxIdentityAuthContext>(null);

// Define the props for the provider component
interface IDPProviderProps {
  idpConfig: DrxIDPAll.DrxIdentityServiceConfig;
  children: ReactNode;

}

// Create a provider component
export function IDPProvider({ idpConfig, children }: IDPProviderProps) {

  /**
   *  user?: LoggedInUser;
    providers?: Array<DrxIdentityProviderConfig>;
    profiles?: Array<DrxIdenityProfile>;
    authenticated?: boolean;
    logginInProgress?: boolean;
    login?: (clientId: String) => void;
    switchProfile?: (profile: String) => void;
    logout?: () => void;    
   */

  const [idpState, setIdpState] = useState({
    user: null,
    token: null,
    autoLogin: idpConfig.autoLogin,
    providers: [],
    selectedProvider: null,
    profiles: [],
    selectedProfile: null,
    authenticated: false,
    logginInProgress: false,
    idpReady: false,
    identityProviderStatus: DrxIDPAll.DrxIdentityProviderStatus.DONE,
  });

  function userLoggedOutFunc() {

    setIdpState((prevState) => ({
      ...prevState,
      user: null,
      token: null,
      authenticated: false,
      selectedProfile: null,
      logginInProgress: false,
    }));
  }


  function userLoggedInFunc(user) {

    if (user.resolverOptions.profiles?.length > 0 && idpState.selectedProfile === null) {
      //setSelectedProfile(user.resolverOptions.profiles[0]);
      setIdpState((prevState) => ({
        ...prevState,
        user: user,
        token: user.token.access_token,
        authenticated: true,
        logginInProgress: false,
        profiles: user.resolverOptions.profiles,
        selectedProfile: user.token.access_token_parsed?.drx_data?.profile_id,
      }));
    } else {
      setIdpState((prevState) => ({
        ...prevState,
        user: user,
        token: user.token.access_token,
        authenticated: true,
        logginInProgress: false,
        profiles: user.resolverOptions.profiles,
      }));
    }
    if (idpConfig.onLogin) {
      return idpConfig.onLogin(user)
    }
    return true;
    //setSelectedProfile(user.selectedProfile);
  }
  function onProcessUpdate(update) {

    setIdpState((prevState) => ({
      ...prevState,
      identityProviderStatus: update,
    }));
    if (idpConfig.onProcessUpdate) {
      return idpConfig.onProcessUpdate(update)
    }
    return true;
  }

  useEffect(() => {
    async function doAync() {
      //NOTE: this code is to make sure the DrxIdentityService is initialized only once
      //During react developing on 'strict' mode the useEffect is called twice

      if (drxIdentityService == null) {
        var settings = Object.assign({}, idpConfig)
        settings.onLogin = userLoggedInFunc
        settings.onProfileSwitch = userLoggedInFunc
        settings.onProcessUpdate = onProcessUpdate
        settings.onLogout = userLoggedOutFunc
        drxIdentityService = new DrxIdentityService(settings)
      } else {
        drxIdentityService.config.onLogin = userLoggedInFunc
        drxIdentityService.config.onProfileSwitch = userLoggedInFunc
        drxIdentityService.config.onProcessUpdate = onProcessUpdate
        drxIdentityService.config.onLogout = userLoggedOutFunc
        console.log("DrxIdentityService already initialized")
      }
      console.log("Use effect called", drxIdentityService.logginInProgress())
      const providers = await drxIdentityService.fetchProviders()

      setIdpState((prevState) => ({
        ...prevState,
        providers: Object.values(providers),
        idpReady: true,
        logginInProgress: drxIdentityService.logginInProgress(),
        selectedProvider: "smart",
      }));
    }
    doAync()
  }, [])


  const OTPLogin = async (otpId, otpCode) => {
    setIdpState((prevState) => ({
      ...prevState,
      logginInProgress: true,
    }));
    try {
      await drxIdentityService.OTPLogin(otpId, otpCode)
    } catch (error) {
      setIdpState((prevState) => ({
        ...prevState,
        logginInProgress: false,
      }));
      throw error
    }
  };

  const login = async (redirectUrl: String = null) => {
    setIdpState((prevState) => ({
      ...prevState,
      logginInProgress: true,
    }));
    console.log("IDPProvier: Login called")
    drxIdentityService.login(redirectUrl)
    //setState({ user, isAuthenticated: true });
  };

  const selectProvider = async (provider: String) => {
    const providerExists = idpState.providers.some((prov) => prov.id === provider);
    if (!providerExists || provider != "smart") {
      console.error(`Provider with id ${provider} not found in provider list.`);
      return;
    }
    await drxIdentityService.selectProvider(provider)
    setIdpState((prevState) => ({
      ...prevState,
      providers: drxIdentityService.providers,
      selectedProvider: provider,
    }));
  }

  const logout = async () => {
    await drxIdentityService.logout()
    setIdpState((prevState) => ({
      ...prevState,
      user: null,
      token: null,
      authenticated: false,
      selectedProfile: null,
    }));

    //setState({ user: null, isAuthenticated: false });
  };

  const switchProfile = async (profile: String) => {
    const profileExists = idpState?.profiles?.some((prof) => prof.profile_id === profile);
    if (!profileExists) {
      console.error(`Profile with id ${profile} not found in profile list.`);
      return;
    }
    await drxIdentityService.switchProfile(profile)

    setIdpState((prevState) => ({
      ...prevState,
      profiles: drxIdentityService.activeUser.resolverOptions.profiles,
      selectedProfile: profile,
    }));
  }

  return (
    <IDPContext.Provider value={{ ...idpState, login, OTPLogin, logout, switchProfile, selectProvider }}>
      {children}
    </IDPContext.Provider>
  );
};

// Custom hook to use the MyContext
export const useIDPContext = () => {
  const context = useContext(IDPContext);
  if (context === undefined) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};
