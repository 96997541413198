
import OpenIDProvider from './identity_providers/OpenIDProvider';
import { DrxIdentityProviderConfig, DrxIdentityProvider, LoginStatus, TokenResult, CPD_HEADERS, DrxResolverData, DrxIdenityProfile } from './identity_providers/types';


export enum DrxIdentityProviderStatus {
    LOGGING_IN = "LOGGING_IN",
    FETCHING_RESOLVER_OPTIONS = "FETCHING_RESOLVER_OPTIONS",
    FETCHING_PROVIDERS = "FETCHING_PROVIDERS",
    SELECTING_PROVIDER = "SELECTING_PROVIDER",
    LOGGING_OUT = "LOGGING_OUT",
    FETCHING_DRX_TOKENS = "FETCHING_DRX_TOKENS",
    SWITCHING_PROFILE = "SWITCHING_PROFILE",
    REFRESHING_TOKEN = "REFRESHING_TOKEN",
    DONE = "DONE",
}


export type DrxIdentityServiceConfig = {
    apiBaseUrl: string;
    providersPath: string;
    resolverPath: string;
    authPath: string;
    otpPath: string;
    wellKnownPath: string;
    preferredProvider?: string;
    autoLogin?: boolean;
    redirectPath?: string;
    onLogin?: (token: LoggedInUser) => void;
    onProfileSwitch?: (token: LoggedInUser) => void;
    onLogout?: () => void;
    onProcessUpdate?: (status: DrxIdentityProviderStatus) => boolean;

}


export type DrxIdentityAuthContext = {
    user?: LoggedInUser;
    providers?: Array<DrxIdentityProviderConfig>;
    selectedProvider?: DrxIdentityProvider;
    profiles?: Array<DrxIdenityProfile>;
    authenticated?: boolean;
    status?: DrxIdentityProviderStatus;
    logginInProgress?: boolean;
    idpReady?: boolean;
    autoLogin?: boolean;
    OTPLogin?: (id: string, otp: string) => void;
    login?: (clientId: String) => void;
    switchProfile?: (profile: String) => void;
    logout?: () => void;
    selectProvider?: (provider: string) => void;

}


export class LoggedInUser {
    token: TokenResult;
    resolverOptions: DrxResolverData;
    provider: DrxIdentityProviderConfig;
    selectedProfile?: DrxIdenityProfile;
    isVisitor: boolean = false;

    constructor(provider: DrxIdentityProviderConfig, token: TokenResult, resolverOptions: DrxResolverData) {
        this.token = token;
        this.provider = provider;
        this.resolverOptions = resolverOptions;
        if (!token.original_token) {
            this.isVisitor = true;
        }

        // var profile_id = token.drx_identity_data_parsed.drx_data.find(item => item.profile_id).profile_id;
        // debugger;
        // if(profile_id) {
        //     this.selectedProfile = resolverOptions.profiles.find((profile) => profile.profile_id === profile_id)
        // }
    }
    //TODO add front-end specific methods
}


export const defaultDevSettings: DrxIdentityServiceConfig = {
    apiBaseUrl: "http://localhost:4005",
    providersPath: "/providers",
    resolverPath: "/resolver_options",
    authPath: "/oauth/authorize",
    otpPath: "/oauth/authorize_otp",
    wellKnownPath: "/.well-known/openid-configuration",
    preferredProvider: "smart" //special case, will select based on the domain name in the providers configurations
}

export class DrxIdentityService {
    config: DrxIdentityServiceConfig;
    activeProvider?: DrxIdentityProvider;
    alreadyFetchingProviders: Promise<any> | null = null;
    activeUser?: LoggedInUser;
    providerConfigs: Map<string, DrxIdentityProviderConfig> = new Map();
    lastProviderPoll: number = 0;
    userLoggedIn: boolean = false;

    status_update_tree = [];

    constructor(config: DrxIdentityServiceConfig) {
        this.config = config;
        if (localStorage.getItem("drx-identity-token")) {
            //TODO fix and store profiles
            this.activeUser = new LoggedInUser({} as DrxIdentityProviderConfig, JSON.parse(localStorage.getItem("drx-identity-token") || "{}"), JSON.parse(localStorage.getItem("drx-resolver-options") || "{}") as DrxResolverData)
            this.userLoggedIn = true;
            this.checkExpired(true);
            if (this.config.onLogin) {
                this.config.onLogin(this.activeUser)
            }
        }

        if (localStorage.getItem("activeProvider")) {
            const asyncSelect = async () => {
                await this.selectProvider(localStorage.getItem("activeProvider") || undefined)
                if (this.activeProvider && this.activeProvider.hasLoginProcess() == false
                    && this.config.autoLogin) {
                    this.login()
                }
            }
            asyncSelect()

        } else {
            if (this.config.autoLogin) {
                this.login()
            }
        }

        setInterval(this.checkExpired.bind(this), 5000)
    }

    logginInProgress() {
        if (this.activeProvider) {
            return this.activeProvider.hasLoginProcess();
        } else {
            return false;
        }

    }

    callbackProcessUpdate(status: DrxIdentityProviderStatus): boolean {
        if (status != DrxIdentityProviderStatus.DONE) {
            this.status_update_tree.push(status)
            if (this.config.onProcessUpdate) {
                return this.config.onProcessUpdate(status)
            } else {
                console.log("No callback for process update: " + status)
            }
        } else {
            if (this.status_update_tree.length > 0) {
                this.status_update_tree.pop()
                if (this.config.onProcessUpdate) {
                    if (this.status_update_tree.length == 0) {
                        this.config.onProcessUpdate(status)
                    } else {
                        return this.config.onProcessUpdate(this.status_update_tree[this.status_update_tree.length - 1])
                    }
                } else {
                    console.log("No callback for process update: " + status)
                }
            }
        }

        return true;
    }

    async checkExpired(silent: boolean = false) {
        if (this.activeUser) {
            const isVisitor = this.activeUser.isVisitor;
            var now = new Date().getTime();
            var expires = this.activeUser.token.access_token_parsed.exp * 1000;
            if (now + 60000 > expires) {
                this.userLoggedIn = false;
                await this.logout();
                window.location.reload();
                if (!isVisitor) {
                    if (!silent) { await this.login(); }
                }
            }
        }
    }

    async logout() {
        if (this.activeUser) {
            const isVisitor = this.activeUser.isVisitor;
            this.callbackProcessUpdate(DrxIdentityProviderStatus.LOGGING_OUT)
            if (this.config.onLogout) {
                this.config.onLogout()
            }
            localStorage.removeItem("activeProvider")
            localStorage.removeItem("drx-identity-token")
            localStorage.removeItem("drx-resolver-options")
            if (!isVisitor) {
                await this.activeProvider.startLogout()
            }
            this.activeUser = undefined;
            this.activeProvider = undefined;
            this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        }
    }

    storeToken() {
        if (this.activeUser) {
            localStorage.setItem("drx-identity-token", JSON.stringify(this.activeUser.token))
            localStorage.setItem("drx-resolver-options", JSON.stringify(this.activeUser.resolverOptions || {}))
        }
    }

    async fetchProviders(): Promise<Array<DrxIdentityProviderConfig>> {
        console.log("Api base url", this.config.apiBaseUrl)
        if (this.lastProviderPoll + 60000 > Date.now()) {
            return Array.from(this.providerConfigs.values())
        }

        if (this.alreadyFetchingProviders) {
            this.callbackProcessUpdate(DrxIdentityProviderStatus.FETCHING_PROVIDERS)
            await this.alreadyFetchingProviders;
        }

        if (!this.providerConfigs.size) {
            var resolvePromise = null;
            this.alreadyFetchingProviders = new Promise(async (resolve, reject) => {
                resolvePromise = resolve;
            })
            var response = await fetch(this.config.apiBaseUrl + this.config.providersPath)
            const providerConfigsArray = await response.json();
            this.providerConfigs = new Map();
            Object.entries(providerConfigsArray).forEach(([key, providerConfig]) => {
                this.providerConfigs.set(providerConfig['id'], providerConfig as DrxIdentityProviderConfig);
            })

            resolvePromise()
            this.alreadyFetchingProviders = null;
        }

        this.lastProviderPoll = Date.now();
        this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        return Array.from(this.providerConfigs.values())

    }

    async updateResolverOptions() {
        if (this.activeUser) {
            this.callbackProcessUpdate(DrxIdentityProviderStatus.FETCHING_RESOLVER_OPTIONS)
            var resolverOptions = await this._fetchResolverOptions(this.activeUser.token.original_token, this.activeProvider.getConfig().drx_client_id)
            this.activeUser.resolverOptions = resolverOptions;
            this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        }
    }

    async _fetchResolverOptions(authToken: string, drx_client_id: string): Promise<DrxResolverData> {
        this.callbackProcessUpdate(DrxIdentityProviderStatus.FETCHING_RESOLVER_OPTIONS)
        var headers = { [CPD_HEADERS.X_CPD_AUTHORIZE]: authToken, [CPD_HEADERS.X_CPD_CLIENT_ID]: drx_client_id }
        var response = await (await fetch(this.config.apiBaseUrl + this.config.resolverPath, {
            method: "POST",
            headers: headers
        })).json()
        this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        return response as DrxResolverData;
    }


    async _retrieveDrxTokens(authToken: string, drx_client_id: string, resolver_options?: string): Promise<TokenResult> {
        this.callbackProcessUpdate(DrxIdentityProviderStatus.FETCHING_DRX_TOKENS)
        var headers = { [CPD_HEADERS.X_CPD_AUTHORIZE]: authToken, [CPD_HEADERS.X_CPD_CLIENT_ID]: drx_client_id }

        if (resolver_options) {
            headers[CPD_HEADERS.X_CPD_RESOLVER_ARGS] = resolver_options
        }
        var response = await (await fetch(this.config.apiBaseUrl + this.config.authPath, {
            method: "POST",
            headers: headers
        })).json()
        var token = response as TokenResult;
        token.access_token_parsed = JSON.parse(atob(token.access_token.split('.')[1]))
        // token.drx_identity_data_parsed = JSON.parse(atob(token.drx_identity_data.split('.')[1]))
        this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        return token;


    }

    async switchProfile(profile_id: string) {
        if (this.activeUser) {
            this.callbackProcessUpdate(DrxIdentityProviderStatus.SWITCHING_PROFILE)
            var config = this.activeProvider.getConfig();
            var drxClientId = config.drx_client_id;
            var drxToken = await this._retrieveDrxTokens(this.activeUser.token.original_token, drxClientId, JSON.stringify({ profile_id: profile_id }))
            var resolverOptions = await this._fetchResolverOptions(this.activeUser.token.original_token, drxClientId)
            this.activeUser = new LoggedInUser(this.activeProvider.getConfig(), drxToken, resolverOptions)
            this.storeToken()
            this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
            if (this.config.onProfileSwitch) {
                this.config.onProfileSwitch(this.activeUser)
            }
        }
    }



    async _identityProviderCallback(status: LoginStatus, token?: string) {
        if (status === LoginStatus.TOKEN_RETRIEVED && token) {
            this.callbackProcessUpdate(DrxIdentityProviderStatus.FETCHING_DRX_TOKENS)
            var drxToken = await this._retrieveDrxTokens(token, this.activeProvider.getConfig().drx_client_id)
            var resolverOptions = await this._fetchResolverOptions(token, this.activeProvider.getConfig().drx_client_id)
            this.activeUser = new LoggedInUser(this.activeProvider.getConfig(), drxToken, resolverOptions)
            this.storeToken()
            this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
            if (this.config.onLogin) {
                this.config.onLogin(this.activeUser)
            }

        }

    }


    async OTPLogin(id: string, otp: string) {
        this.callbackProcessUpdate(DrxIdentityProviderStatus.FETCHING_DRX_TOKENS)
        const response = await fetch(this.config.apiBaseUrl + this.config.otpPath, {
            method: 'POST',
            headers: {
                "X-CPD-OTP-ID": id,
                "X-CPD-OTP-CODE": otp,
            },
        });

        const data = await response.json();
        if (data.error) {
            this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
            throw new Error(data.error)
        }

        console.log("OTPLogin Result", data)
        var drxToken = data as TokenResult;
        drxToken.access_token_parsed = JSON.parse(atob(drxToken.access_token.split('.')[1]))
        this.activeUser = new LoggedInUser({} as DrxIdentityProviderConfig, drxToken, {})
        this.storeToken()
        this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        if (this.config.onLogin) {
            this.config.onLogin(this.activeUser)
        }

    }

    async login(redirectPath: string = window.location.href) {

        if (this.activeProvider) {
            console.log("DrxIdentityService login called")
            this.callbackProcessUpdate(DrxIdentityProviderStatus.LOGGING_IN)
            await this.activeProvider.startLogin(this.config.redirectPath || redirectPath)
            this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
        } else {
            await this.selectProvider()
            await this.login(redirectPath)
        }
    }

    selectSmartProvider() {
        var toFind = window.location.host;
        var provider;
        for (const config of Array.from(this.providerConfigs.values())) {
            if (config.domain_names.includes(toFind)) {
                provider = config;
                break;
            }
        }
        if (provider) {

            return provider.id;
        } else {
            alert("Smart selector could not find a provider for this domain. Total domains: " + this.providerConfigs.size + " for " + toFind + " domain")
        }
        return null;
    }

    async selectProvider(provider?: string) {
        //Fetch providers, is lazy loaded
        this.callbackProcessUpdate(DrxIdentityProviderStatus.SELECTING_PROVIDER)
        await this.fetchProviders()

        if (!provider) {
            provider = this.config.preferredProvider;
            if (provider === "smart") {
                provider = this.selectSmartProvider();
            }
            if (!provider) {
                provider = this.providerConfigs.keys().next().value;
            }
        }
        var activeProviderConfig = this.providerConfigs.get(provider || "");
        if (!activeProviderConfig) {
            throw new Error("Provider not found");
        }
        switch (activeProviderConfig.idType) {
            case "openid":
                this.activeProvider = new OpenIDProvider(activeProviderConfig, this._identityProviderCallback.bind(this));
                break;
            default:
                throw new Error("Provider type not supported");
        }
        if (this.activeProvider) {
            localStorage.setItem("activeProvider", provider || "");
            //this.callbackProcessUpdate(DrxIdentityProviderStatus.LOGGING_IN)

            await this.activeProvider.retrieveToken();
        }

        this.callbackProcessUpdate(DrxIdentityProviderStatus.DONE)
    }


}
