import { OAuth2Client, generateCodeVerifier } from '@badgateway/oauth2-client';
import { DrxIdentityProvider, DrxIdentityProviderConfig, LoginStatus } from './types';

import { DrxIdentityService } from '..';

const drxCustomData = 'drx-oidc-login'
const localStorageFields = ['codeVerifier', 'token', 'redirect']
/**
 * Not to be used directly by the user
 * Always directy use DrxIdentityService
 */

export default class OpenIDProvider implements DrxIdentityProvider {
    client: OAuth2Client;
    isAuth0: boolean;
    providerConfig: DrxIdentityProviderConfig;
    codeVerifier?: string;
    refeshToken?: string;

    process_callback: (status: LoginStatus, token?: string, refeshToken?: string) => Promise<void>;
    constructor(provider: DrxIdentityProviderConfig, process_callback: (status: LoginStatus, token?: string) => Promise<void>) {
        if (provider.idType !== 'openid') {
            throw new Error('Invalid provider type');
        }
        this.isAuth0 = provider.config.authorization_endpoint.includes('auth0');
        this.providerConfig = provider;
        this.client = new OAuth2Client({
            server: provider.config.domain,
            clientId: provider.config.client_id,
            tokenEndpoint: provider.config.token_endpoint,
            authorizationEndpoint: provider.config.authorization_endpoint,
        });

        this.process_callback = process_callback
    }

    clearCodeVerifier() {
        localStorage.removeItem(this.providerConfig.id + ':codeVerifier');
        localStorage.removeItem(this.providerConfig.id + ':token');
        window.location.reload();
    }


    async startLogout() {
        try {
            this.doCallback(LoginStatus.LOGGING_OUT)
            window.location.assign(this.providerConfig.config.logout_endpoint)
        } catch (e) {
            console.error(e)
            await this.doCallback(LoginStatus.ERROR)
        }
    }

    async startLogin(redirectPath: string = "/") {

        try {
            this.doCallback(LoginStatus.LOGGING_IN)
            console.log("OpenIDProvider: Login called")

            const client = this.client

            const codeVerifier = (await generateCodeVerifier());
            localStorage.setItem(this.providerConfig.id + ':codeVerifier', codeVerifier);

            if (redirectPath) localStorage.setItem(this.providerConfig.id + ':redirect', redirectPath);

            var scope = this.providerConfig.config.scope.split(' ')
            const location = await client.authorizationCode.getAuthorizeUri({
                redirectUri: window.origin + (this.providerConfig.config.redirect_uri || ''),
                state: drxCustomData,
                codeVerifier,
                scope: scope,

            });
            console.log("OpenIDProvider: Redirecting to " + location)
            document.location = location;
        } catch (e) {
            console.error(e)
            await this.doCallback(LoginStatus.ERROR)
        }

    }

    getConfig() {
        return this.providerConfig;
    }

    async doCallback(status: LoginStatus, token?: string, refeshToken?: string) {
        if (this.process_callback) {
            await this.process_callback(status, token, refeshToken)
        }
    }

    hasLoginProcess() {
        return localStorage.getItem(this.providerConfig.id + ':codeVerifier') !== null
    }

    async retrieveToken() {
        this.codeVerifier = localStorage.getItem(this.providerConfig.id + ':codeVerifier') || undefined;
        if (this.codeVerifier) {

            var redirect = localStorage.getItem(this.providerConfig.id + ':redirect') || undefined;
            await this.doCallback(LoginStatus.LOGGING_IN)
            try {

                const client = this.client;
                var oauth2Token: any = {}
                //Nasty auth0 specific stuff
                //TODO check if this is not just the default authorization_code flow
                if (this.isAuth0) {

                    const response = await fetch(this.providerConfig.config.token_endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            grant_type: 'authorization_code',
                            code: new URL(document.location.href).searchParams.get('code'),
                            redirect_uri: window.origin,
                            code_verifier: this.codeVerifier,
                            client_id: this.providerConfig.config.client_id
                        })
                    });
                    const data = await response.json();


                    oauth2Token['accessToken'] = data.id_token
                    localStorage.removeItem(this.providerConfig.id + ':codeVerifier')

                } else {
                    localStorage.removeItem(this.providerConfig.id + ':codeVerifier')
                    oauth2Token = await client.authorizationCode.getTokenFromCodeRedirect(
                        document.location.href,
                        {
                            /**
                             * The redirect URI is not actually used for any redirects, but MUST be the
                             * same as what you passed earlier to "authorizationCode"
                             */
                            redirectUri: window.origin + (this.providerConfig.config.redirect_uri || ''),

                            /**
                             * This is optional, but if it's passed then it also MUST be the same as
                             * what you passed in the first step.
                             *
                             * If set, it will verify that the server sent the exact same state back.
                             */
                            state: drxCustomData,

                            codeVerifier: this.codeVerifier,

                        }
                    )
                }


                //TODO see if we want to keep the token and process from token state later
                //should probably be done in the 
                //localStorage.setItem(this.providerConfig.id + 'token', JSON.stringify(oauth2Token));

                const nextURL = (redirect || window.origin)
                const nextTitle = document.title;
                const nextState = { pathname: nextURL, state: { isActive: true } } //{ additionalInformation: 'Updated the URL with JS' };
                window.history.replaceState(nextState, nextTitle, nextURL);
                localStorage.removeItem(this.providerConfig.id + ':codeVerifier');
                localStorage.removeItem(this.providerConfig.id + ':redirect');

                //TODO call service to get user data

                await this.doCallback(LoginStatus.TOKEN_RETRIEVED, oauth2Token.accessToken)
                return oauth2Token;
            }
            catch (e) {
                console.error(e)
                this.doCallback(LoginStatus.ERROR)
                return null
            }

        }
    }


}